import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const { CONTEXT_PATH } = publicRuntimeConfig

/**
 * we need to inject a context root (if there is one ) in front of every local link
 * @param path string: the path you want to link to.
 */
export default (path: string): string => {
  const context: string = CONTEXT_PATH
  if (!context) {
    return path
  }
  const targetPath = path.charAt(0) === '/' ? path.slice(1) : path
  return `${context}/${targetPath}`
}
